.modal-area {
    padding: 24px;
    max-width: 1024px;

    h4 {
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .add-sentence {
        h4 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        margin-bottom: 15px;

        .copy_text {
            display: flex;

            input, 
            .ant-select-selection {
                flex: 1;
                margin-right: 15px;
            }

            button {
                width: 80px;
            }
        }
    }

    .two-section {
        display: flex;

        .left {
            width: 50%;
        }

        .right {
            width: 50%;
            margin-left: 50px;
        }
    }

    .answer-option {
        display: flex;

        input {
            flex: 1;
            margin-right: 15px;
        }
        
        button {
            width: 80px;;
        }
    }
}