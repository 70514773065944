@import './common/variables';

@import './common/reset';

body {
    color: $font-color;
    font-size: 16px;
}

.ant-table-wrapper {
    margin: 24px;

    table {
        border: 1px solid #e8e8e8;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $font-color;
}

.editor {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.red {
    color: red;
}

.editor-wrap {
    margin-top: 20px;
}