.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-upload-list-picture-card {
  .ant-upload-list-item {
    width: 170px;
  }
}

.header-time {
  float: right;
}

.suggestion-content {
  color: #999;
}