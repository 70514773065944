.sider-nav {
    height: 100vh;
    overflow-y: hidden;


    .logo {
        height: 32px;
        line-height: 32px;
        color: #fff;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
        text-align: center;
    }
}