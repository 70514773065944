.login-page {
    background: #152837;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .container {
        width: 320px;
        margin: 0 auto;

        .title {
            color: white;
            font-size: 24px;
            text-align: center;
            margin-bottom: 25px;
        }

        .login-form {
            .ant-row {
                height: 46px;
                padding: 0 5px;
                margin-bottom: 35px;
                border-radius: 50px;
                position: relative;
                border: rgba(255,255,255,0.2) 2px solid;
    
                input.ant-input {
                    outline: none;
                    border: none;
                    background: none;
                    color: #EEE;
    
                    &:focus {
                        border: 0;
                        background: none;
                        box-shadow: none;
                    }
                }
    
                .ant-form-explain {
                    margin-top: 10px;
                    color: #ff9800;
                }
            }

            .captcha-box {
                display: flex;

                .ant-row {
                    flex: 1;
                    margin-right: 10px;
                }

                .captcha-image {
                    width: 120px;
                    padding-top: 7px;
                }
            }

            .ant-alert {
                margin-bottom: 15px;
            }

            .bottom {
                width: 100%;
                height: 50px;
                button {
                    display: block;
                    height: 50px;
                    width: 100%;
                    line-height: 20px;
                    text-align: center;
                    font-size: 20px;
                    border-radius: 50px;
                    background: #0096e6;
                    padding: 15px;
                }
            }


        }
    }
}