
@import './common.scss';


/*
import pages
*/
@import './pages/login.scss';
@import './pages/admin.scss';


/*
import component
*/
@import './components/modal.scss';
@import './components/table.scss';

#root {
    width: 100%;
    height: 100%;
}
